import axios from 'axios';

const API_URL = 'http://localhost:8080/api/ressources/';

function authHeader() {
    const user = JSON.parse(localStorage.getItem('user'));
    if (user && user.accessToken) {
        return { Authorization: 'Bearer ' + user.accessToken };
    } else {
        return {};
    }
}

const getAllRessources = () => {
    return axios.get(API_URL, { headers: authHeader() });
};

const getRessourceById = (id) => {
    return axios.get(API_URL + id, { headers: authHeader() });
};

const addRessource = (formData) => {
    return axios.post(API_URL + 'add', formData, { headers: { ...authHeader(), 'Content-Type': 'multipart/form-data' } });
};

const updateRessource = (id, data) => {
    return axios.put(API_URL + id, data, { headers: authHeader() });
};

const deleteRessource = (id) => {
    return axios.delete(API_URL + id, { headers: authHeader() });
};

export const ressourceService = {
    getAllRessources,
    getRessourceById,
    addRessource,
    updateRessource,
    deleteRessource
};
