import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, TextField, Button, Checkbox, FormControlLabel } from '@mui/material';
import { ressourceService } from '../../services/ressource.service';

function AddRessourceDialog({ open, onClose }) {
    const [nom, setNom] = useState('');
    const [matiere, setMatiere] = useState('');
    const [cycleEnseignement, setCycleEnseignement] = useState('');
    const [description, setDescription] = useState('');
    const [type, setType] = useState('');
    const [estPrive, setEstPrive] = useState(false);
    const [fileType, setFileType] = useState('');
    const [file, setFile] = useState(null);

    const handleSubmit = async () => {
        const formData = new FormData();
        formData.append('nom', nom);
        formData.append('matiere', matiere);
        formData.append('cycleEnseignement', cycleEnseignement);
        formData.append('description', description);
        formData.append('type', type);
        formData.append('estPrive', estPrive);
        formData.append('fileType', fileType);
        formData.append('file', file);

        try {
            await ressourceService.addRessource(formData);
            onClose();
        } catch (error) {
            console.error('Failed to add resource', error);
        }
    };

    return (
        <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
            <DialogTitle>Ajouter une nouvelle ressource</DialogTitle>
            <DialogContent>
                <TextField margin="dense" label="Nom de la ressource" fullWidth variant="outlined" value={nom} onChange={e => setNom(e.target.value)} />
                <TextField margin="dense" label="Matière" fullWidth variant="outlined" value={matiere} onChange={e => setMatiere(e.target.value)} />
                <TextField margin="dense" label="Cycle d'enseignement" fullWidth variant="outlined" value={cycleEnseignement} onChange={e => setCycleEnseignement(e.target.value)} />
                <TextField margin="dense" label="Description" fullWidth multiline rows={4} variant="outlined" value={description} onChange={e => setDescription(e.target.value)} />
                <TextField margin="dense" label="Type (Exercice, Évaluation)" fullWidth variant="outlined" value={type} onChange={e => setType(e.target.value)} />
                <TextField margin="dense" label="Type de fichier (PDF, DOCX, JPEG, etc.)" fullWidth variant="outlined" value={fileType} onChange={e => setFileType(e.target.value)} />
                <FormControlLabel control={<Checkbox checked={estPrive} onChange={e => setEstPrive(e.target.checked)} />} label="Est privé ?" />
                <Button variant="contained" component="label">
                    Joindre fichier
                    <input type="file" hidden onChange={e => setFile(e.target.files[0])} />
                </Button>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">Annuler</Button>
                <Button onClick={handleSubmit} color="primary">Ajouter</Button>
            </DialogActions>
        </Dialog>
    );
}

export default AddRessourceDialog;
