import axios from 'axios';
import authHeader from './auth-header';

const API_URL = 'http://localhost:8080/api/posts/';
const COMMENTS_API_URL = 'http://localhost:8080/api/comments/';
const LIKES_API_URL = 'http://localhost:8080/api/likes/';
const user = JSON.parse(localStorage.getItem('user'));

const getCurrentUser = () => {
  return user;
};
export const postService = {
  fetchAllPosts() {
    return axios.get(API_URL, { headers: authHeader() });
  },
  fetchPostsForUser(userId) {
    return axios.get(`${API_URL}?userId=${userId}`, { headers: authHeader() });
  },
  getPostById(postId) {
    return axios.get(`${API_URL}${postId}`, { headers: authHeader() });
  },
  getLikes(postId) {
    return axios.get(`${LIKES_API_URL}getLikes`, {
      params: { postId },
      headers: authHeader()
    })
      .then(response => {
        console.log('Likes data:', response.data);
        return response.data;
      })
      .catch(error => {
        console.error('Error fetching likes:', error);
        return [];
      });
  },
  likePost(postId) {
    return axios.post(`${LIKES_API_URL}likePost`,
      { postId, userId: getCurrentUser().id },
      { headers: authHeader() }
    );
  },
  unlikePost(postId) {
    return axios.delete(`${LIKES_API_URL}unlikePost`, {
      params: { postId, userId: getCurrentUser().id },
      headers: authHeader()
    });
  },
  deletePost(postId) {
    return axios.delete(`${API_URL}${postId}`, { headers: authHeader() });
  },
  createPost(postData) {
    return axios.post(`${API_URL}addPost`, postData, { headers: { ...authHeader(), 'Content-Type': 'multipart/form-data' } });
  },
  createComment(commentData) {
    return axios.post(`${COMMENTS_API_URL}add`, commentData, { headers: authHeader() });
  },
  getComments(postId) {
    return axios.get(`${COMMENTS_API_URL}post/${postId}`, { headers: authHeader() });
  },
  deleteComment(commentId) {
    return axios.delete(`${COMMENTS_API_URL}comments/${commentId}`, { headers: authHeader() });
  },
  updateComment(commentId, updatedComment) {
    return axios.put(`${COMMENTS_API_URL}${commentId}`, updatedComment, { headers: authHeader() });
  }
};
