import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import userService from "../../services/user.service";

const EditProfile = () => {
  const { user: currentUser } = useSelector((state) => state.auth);
  const [profilePicture, setProfilePicture] = useState(null);
  const [initialValues, setInitialValues] = useState({
    bio: "",
    // autres valeurs initiales
  });

  useEffect(() => {
    if (currentUser) {
      setInitialValues({
        bio: currentUser.bio || "",
        // autres valeurs
      });
    }
  }, [currentUser]);

  const validationSchema = Yup.object().shape({
    bio: Yup.string().max(500, "La biographie ne peut pas dépasser 500 caractères."),
  });

  const handleSubmit = (values) => {
    userService.updateProfile(values)
      .then(() => {
        // ok
      })
      .catch((error) => {
        // erreur
      });
  };

  const handleProfilePictureChange = (event) => {
    const file = event.target.files[0];
    setProfilePicture(file);

    userService.updateProfilePicture(file)
      .then(() => {
        // ok
        window.location.reload(); // Actualiser la page pour voir la nouvelle image
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className="edit-profile-form">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize={true}
      >
        <Form>
          <div className="form-group">
            <label htmlFor="bio">Biographie</label>
            <Field name="bio" type="text" className="form-control" />
            <ErrorMessage name="bio" component="div" className="alert alert-danger" />
          </div>
          {/* autres champs */}
          <div className="form-group">
            <label htmlFor="profilePicture">Photo de profil</label>
            <input
              type="file"
              id="profilePicture"
              name="profilePicture"
              accept="image/*"
              onChange={handleProfilePictureChange}
              className="form-control"
            />
          </div>
          <div className="form-group">
            <button type="submit" className="btn btn-primary btn-block">Enregistrer</button>
          </div>
        </Form>
      </Formik>
    </div>
  );
};

export default EditProfile;
