import axios from 'axios';

const API_URL = 'http://localhost:8080/api/matieres/';

// Fonction pour récupérer le token stocké localement
function authHeader() {
    const user = JSON.parse(localStorage.getItem('user'));
    if (user && user.accessToken) {
        return { Authorization: 'Bearer ' + user.accessToken };
    } else {
        return {};
    }
}

const getAllMatieres = () => {
    return axios.get(API_URL, { headers: authHeader() });
};

const getMatiereById = (id) => {
    return axios.get(API_URL + id, { headers: authHeader() });
};

const createMatiere = (data) => {
    return axios.post(API_URL, data, { headers: authHeader() });
};

const updateMatiere = (id, data) => {
    return axios.put(API_URL + id, data, { headers: authHeader() });
};

const deleteMatiere = (id) => {
    return axios.delete(API_URL + id, { headers: authHeader() });
};

export const matiereService = {
    getAllMatieres,
    getMatiereById,
    createMatiere,
    updateMatiere,
    deleteMatiere
};
