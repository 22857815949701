import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Paper, TextField, Button, Typography } from '@mui/material';
import { forumService } from '../../services/forum.service';

const CreatePost = () => {
  const { threadId } = useParams();
  const [content, setContent] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    const post = { content, threadId };
    await forumService.createPost(threadId, post);
    navigate(`/forums/thread/${threadId}`);
  };

  return (
    <Paper sx={{ padding: 2 }}>
      <Typography variant="h4" component="h2" sx={{ marginBottom: 2 }}>
        Écrire un post
      </Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          label="Content"
          value={content}
          onChange={(e) => setContent(e.target.value)}
          fullWidth
          multiline
          rows={4}
          sx={{ marginBottom: 2 }}
        />
        <Button variant="contained" color="primary" type="submit">
          Publier
        </Button>
      </form>
    </Paper>
  );
};

export default CreatePost;
