import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { List, ListItem, ListItemText, Paper, Typography, TextField, Button } from '@mui/material';
import { messageService } from '../../services/message.service';
import './MessageDetail.scss';

const MessageDetail = () => {
  const { id } = useParams();
  const queryClient = useQueryClient();
  const [newMessage, setNewMessage] = useState('');

  const { data: messages = [], isLoading, isError } = useQuery({
    queryKey: ['messages', id],
    queryFn: () => messageService.getDiscussionMessages(id)
  });

  useEffect(() => {
    if (messages.length > 0) {
      console.log("Marking messages as read for discussion ID:", id);
      messageService.markAsRead(id).then(() => {
        console.log("Messages marked as read");
      }).catch((error) => {
        console.error("Error marking messages as read:", error);
      });
    }
  }, [messages, id]);

  const mutation = useMutation({
    mutationFn: (newMessage) => messageService.sendMessage(id, newMessage),
    onSuccess: () => queryClient.invalidateQueries(['messages', id]),
  });

  const handleSendMessage = () => {
    if (newMessage.trim() === '') return;
    mutation.mutate(newMessage);
    setNewMessage('');
  };

  return (
    <Paper className="message-detail-container">
      <Typography variant="h4" component="h1" className="title">
        Messages
      </Typography>
      {isLoading ? (
        <Typography>Chargement...</Typography>
      ) : isError ? (
        <Typography>Erreur lors du chargement des messages</Typography>
      ) : messages.length === 0 ? (
        <Typography>Envoyez le premier message :</Typography>
      ) : (
        <List className="message-list">
          {messages.map((message) => (
            <ListItem key={message.id} className="message-item">
              <ListItemText
                primary={message.content}
                secondary={
                  <>
                    <Typography component="span" variant="body2" color="textPrimary" display="inline">
                      {message.sender.username}
                    </Typography>
                    {" — "}
                    {new Date(message.sentAt).toLocaleString()}
                  </>
                }
              />
            </ListItem>
          ))}
        </List>
      )}
      <div className="message-input-container">
        <TextField
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
          placeholder="Type your message..."
          variant="outlined"
          fullWidth
          className="message-input"
        />
        <Button variant="contained" color="primary" onClick={handleSendMessage} className="send-button">
          Send
        </Button>
      </div>
    </Paper>
  );
};

export default MessageDetail;
