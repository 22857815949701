import axios from 'axios';
import authHeader from './auth-header';  // Récupère le header d'authentification avec le token JWT

const API_URL = 'http://localhost:8080/api/user/';

const getRecommendations = () => {
  return axios.get(`${API_URL}recommendations`, { headers: authHeader() })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      console.error('Error fetching recommendations:', error);
      return [];
    });
};

const followUser = (targetUserId) => {
    return axios.post(`${API_URL}follow/${targetUserId}`, {}, { headers: authHeader() })
      .then(response => response.data)
      .catch(error => {
        console.error('Error following user:', error);
      });
  };

const unfollowUser = (userId) => {
  return axios.post(`${API_URL}unfollow/${userId}`, {}, { headers: authHeader() });
};

const getOnlineFriends = () => {
    return axios.get(`${API_URL}online-friends`, { headers: authHeader() })
      .then(response => {
        return response.data;
      })
      .catch(error => {
        console.error('Erreur lors de la récupération des amis en ligne:', error);
        return [];
      });
  };
  

export const rightBarService = {
  getRecommendations,
  followUser,
  unfollowUser,
  getOnlineFriends
};
