import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { forumService } from '../../services/forum.service';
import { CircularProgress, Typography, List, ListItem, ListItemText, Paper, Button } from '@mui/material';

const ForumThreadList = () => {
  const { categoryId } = useParams();
  const [threads, setThreads] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchThreads = async () => {
      try {
        const data = await forumService.getThreads(categoryId);
        setThreads(data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchThreads();
  }, [categoryId]);

  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    return <Typography color="error">Error: {error}</Typography>;
  }

  return (
    <Paper>
      <Typography variant="h4" component="h2" sx={{ marginBottom: 2 }}>
        Forum Threads
      </Typography>
      <List>
        {threads.map((thread) => (
          <ListItem key={thread.id} component={Link} to={`/forums/thread/${thread.id}`} button>
            <ListItemText primary={thread.title} />
          </ListItem>
        ))}
      </List>
      <Button variant="contained" color="primary" component={Link} to={`/forums/category/${categoryId}/create-thread`}>
        Créer un nouveau thread
      </Button>
    </Paper>
  );
};

export default ForumThreadList;
