import React, { useState, useEffect } from 'react';
import { postService } from '../../services/post.service';
import { useSelector } from 'react-redux';
import { TextField, Button } from "@mui/material";
import Comment from "./Comment";

const CommentList = ({ postId }) => {
  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState("");
  const { user: currentUser } = useSelector((state) => state.auth);

  useEffect(() => {
    fetchComments();
  }, []);

  const fetchComments = async () => {
    try {
      const { data } = await postService.getComments(postId);
      setComments(data);
    } catch (error) {
      console.error("Failed to fetch comments", error);
    }
  };

  const handleAddComment = async () => {
    if (!newComment) return;

    const commentData = {
      postId,
      userId: currentUser.id,
      content: newComment,
    };

    try {
      await postService.createComment(commentData);
      setNewComment("");
      fetchComments();
    } catch (error) {
      console.error("Failed to add comment", error);
    }
  };

  const handleDeleteComment = () => {
    fetchComments(); // Refetch comments quand deleted
  };

  return (
    <div className="comments-section">
      <div className="comments-list">
        {comments.map((comment) => (
          <Comment
            key={comment.id}
            comment={comment}
            currentUser={currentUser}
            onDelete={handleDeleteComment}
          />
        ))}
      </div>
      <div className="add-comment">
        <TextField
          label="Ajouter un commentaire..."
          variant="outlined"
          fullWidth
          margin="normal"
          value={newComment}
          onChange={(e) => setNewComment(e.target.value)}
        />
        <Button variant="contained" color="primary" onClick={handleAddComment}>
          Commenter
        </Button>
      </div>
    </div>
  );
};

export default CommentList;
