import React, { useEffect, useState } from 'react';
import { Button, Card, Container, ListGroup } from 'react-bootstrap';
import adminService from '../../services/admin.service';

const ManageModerators = () => {
    const [requests, setRequests] = useState([]);
    const [error, setError] = useState('');

    useEffect(() => {
        fetchModeratorRequests();
    }, []);

    const fetchModeratorRequests = async () => {
        try {
            const response = await adminService.getPendingModeratorRequests();
            setRequests(response.data);
        } catch (err) {
            setError('Error fetching moderator requests');
        }
    };

    const handleApprove = async (requestId) => {
        try {
            await adminService.approveModeratorRequest(requestId);
            setRequests(requests.filter(request => request.id !== requestId));
        } catch (err) {
            setError('Error approving moderator request');
        }
    };

    const handleReject = async (requestId) => {
        try {
            await adminService.rejectModeratorRequest(requestId);
            setRequests(requests.filter(request => request.id !== requestId));
        } catch (err) {
            setError('Error rejecting moderator request');
        }
    };

    return (
        <Container>
            <h2>Manage Moderator Requests</h2>
            {error && <p className="text-danger">{error}</p>}
            {requests.length === 0 ? (
                <p>No pending requests.</p>
            ) : (
                <ListGroup>
                    {requests.map(request => (
                        <ListGroup.Item key={request.id}>
                            <Card>
                                <Card.Body>
                                    <Card.Title>{request.userId}</Card.Title>
                                    <Card.Text>{request.reason}</Card.Text>
                                    <Button variant="success" onClick={() => handleApprove(request.id)}>Approve</Button>
                                    <Button variant="danger" onClick={() => handleReject(request.id)}>Reject</Button>
                                </Card.Body>
                            </Card>
                        </ListGroup.Item>
                    ))}
                </ListGroup>
            )}
        </Container>
    );
};

export default ManageModerators;
