import axios from "axios";
import authHeader from "./auth-header";


const API_URL = "http://localhost:8080/api/user/";
const API_TEST = "http://localhost:8080/api/test/";

const updateProfile = (profileData) => {
  return axios.put(API_URL + "update", profileData, { headers: authHeader() })
    .then(response => {
      if (response.data) {
        const user = JSON.parse(localStorage.getItem('user'));
        user.bio = profileData.bio;
        localStorage.setItem('user', JSON.stringify(user));
      }
      return response.data;
    });
};

// const updateProfilePicture = (profilePicture) => {
//   const formData = new FormData();
//   formData.append("profilePicture", profilePicture);

//   return axios.put(API_URL + "updateProfilePicture", formData, {
//     headers: {
//       ...authHeader(),
//       "Content-Type": "multipart/form-data",
//     },
//   }).then(response => {
//     if (response.data) {
//       const user = JSON.parse(localStorage.getItem('user'));
//       user.profilePictureUrl = "/resources/user-profile-pictures/" + response.data.fileName;
//       localStorage.setItem('user', JSON.stringify(user));
//     }
//     return response.data;
//   });
// };
const updateProfilePicture = (profilePicture) => {
  const formData = new FormData();
  formData.append("profilePicture", profilePicture);
  return axios.post(API_URL + 'updateProfilePicture', formData, { headers: { ...authHeader(), 'Content-Type': 'multipart/form-data' } });
};


const getPublicContent = () => {
  return axios.get(API_TEST + "all");
};


const userService = {
  getPublicContent,
};

const getUserById = (id) => {
  return axios.get(`${API_URL}${id}`, { headers: authHeader() });
};


export default {
  updateProfile,
  updateProfilePicture,
  getPublicContent,
  getUserById
};