import React from 'react';
import { Dialog, DialogTitle, DialogContent, Avatar, Button } from '@mui/material';

const UserProfileDialog = ({ open, onClose, user }) => {
  if (!user) return null;

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Profil de {user.username}</DialogTitle>
      <DialogContent>
        <Avatar 
          src={user.profilePictureUrl ? `http://localhost:8080${user.profilePictureUrl}` : "/default-profile.png"}
          alt={user.username}
          style={{ width: '100px', height: '100px', marginBottom: '10px' }}
        />
        <p><strong>Email :</strong> {user.email || "Non spécifié"}</p>
        <p><strong>Biographie :</strong> {user.bio || "Non spécifiée"}</p>
        <p><strong>Matières enseignées :</strong> 
          {user.subjects && user.subjects.length > 0 ? (
            <ul>
              {user.subjects.map((subject, index) => <li key={index}>{subject}</li>)}
            </ul>
          ) : (
            "Non spécifié"
          )}
        </p>
        <p><strong>Années d'expérience :</strong> {user.yearsOfExperience || "Non spécifié"}</p>
        <p><strong>Qualifications :</strong> {user.qualifications || "Non spécifié"}</p>
        
        <p><strong>Cycles enseignés :</strong> 
          {user.cycles && user.cycles.length > 0 ? (
            <ul>
              {user.cycles.map((cycle, index) => <li key={index}>{cycle.name}</li>)}
            </ul>
          ) : (
            "Non spécifié"
          )}
        </p>
        
        <Button onClick={onClose} variant="contained" style={{ marginTop: '10px' }}>
          Fermer
        </Button>
      </DialogContent>
    </Dialog>
  );
};

export default UserProfileDialog;
