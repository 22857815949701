import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { matiereService } from '../services/matiere.service';

export const fetchAllMatieres = createAsyncThunk(
    'matieres/fetchAll',
    async () => {
        const response = await matiereService.getAllMatieres();
        return response.data;
    }
);

const initialState = {
    matieres: [],
    isLoading: false,
    error: null
};

const matiereSlice = createSlice({
    name: 'matieres',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllMatieres.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(fetchAllMatieres.fulfilled, (state, action) => {
                state.matieres = action.payload;
                state.isLoading = false;
            })
            .addCase(fetchAllMatieres.rejected, (state, action) => {
                state.error = action.error.message;
                state.isLoading = false;
            });
    }
});

export default matiereSlice.reducer;
