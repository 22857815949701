import React, { useEffect, useState } from 'react';
import { Button, Card, Container, ListGroup } from 'react-bootstrap';
import adminService from '../../services/admin.service';

const ManageReports = () => {
    const [reports, setReports] = useState([]);
    const [error, setError] = useState('');

    useEffect(() => {
        fetchReports();
    }, []);

    const fetchReports = async () => {
        try {
            const response = await adminService.getReports();
            setReports(response.data);
        } catch (err) {
            setError('Error fetching reports');
        }
    };

    const handleResolve = async (reportId) => {
        try {
            await adminService.resolveReport(reportId);
            setReports(reports.filter(report => report.id !== reportId));
        } catch (err) {
            setError('Error resolving report');
        }
    };

    return (
        <Container>
            <h2>Manage Reports</h2>
            {error && <p className="text-danger">{error}</p>}
            {reports.length === 0 ? (
                <p>No unresolved reports.</p>
            ) : (
                <ListGroup>
                    {reports.map(report => (
                        <ListGroup.Item key={report.id}>
                            <Card>
                                <Card.Body>
                                    <Card.Title>Reported Post ID: {report.postId}</Card.Title>
                                    <Card.Text>Reason: {report.reason}</Card.Text>
                                    <Card.Text>Reported By: {report.reporterId}</Card.Text>
                                    <Button variant="success" onClick={() => handleResolve(report.id)}>Resolve</Button>
                                </Card.Body>
                            </Card>
                        </ListGroup.Item>
                    ))}
                </ListGroup>
            )}
        </Container>
    );
};

export default ManageReports;
