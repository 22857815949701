import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Paper, TextField, Button, Typography } from '@mui/material';
import { forumService } from '../../services/forum.service';

const CreateCategory = () => {
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    const category = { name, description };
    await forumService.createCategory(category);
    navigate('/forums');
  };

  return (
    <Paper sx={{ padding: 2 }}>
      <Typography variant="h4" component="h2" sx={{ marginBottom: 2 }}>
        Créer une nouvelle catégorie
      </Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          label="Category Name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          fullWidth
          sx={{ marginBottom: 2 }}
        />
        <TextField
          label="Description"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          fullWidth
          multiline
          rows={4}
          sx={{ marginBottom: 2 }}
        />
        <Button variant="contained" color="primary" type="submit">
          Créer
        </Button>
      </form>
    </Paper>
  );
};

export default CreateCategory;
