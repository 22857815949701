import axios from 'axios';
import authHeader from './auth-header';

const API_URL = 'http://localhost:8080/api/notifications/';

export const notificationService = {
    getUserNotifications(userId) {
        return axios.get(`${API_URL}${userId}`, { headers: authHeader() });
    },
    getUnreadNotifications(userId) {
        return axios.get(`${API_URL}${userId}/unread`, { headers: authHeader() });
    },
    markAsRead(notificationId) {
        return axios.patch(`${API_URL}${notificationId}/read`, {}, { headers: authHeader() });
    }
};
