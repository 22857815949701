import React, { useState, useEffect } from "react";
import UserService from "../../services/user.service";
import { useNavigate } from "react-router-dom";
import './Home.css';

const Home = () => {
  const [content, setContent] = useState("");
  const navigate = useNavigate(); // Hook pour la navigation
  const handleStart = () => {
    navigate("/login");
  };

  useEffect(() => {
    UserService.getPublicContent().then(
      (response) => {
        setContent(response.data);
      },
      (error) => {
        const _content =
          (error.response && error.response.data) ||
          error.message ||
          error.toString();
        setContent(_content);
      }
    );
  }, []);

  return (
    <div className="container">

      <header className="hero">
        <div className="overlay">
          <h1>Bienvenue sur InstitShare</h1>
          <p>La plateforme dédiée aux enseignants</p>
          <button onClick={handleStart}>Commencer</button>
        </div>
      </header>

      <section id="about" className="info-section">
        <h2>À propos de nous</h2>
        <p>
          InstitShare est une plateforme conçue pour transformer l'éducation. 
          Nous facilitons la collaboration, l'accès aux ressources, et bien plus encore.
        </p>
      </section>

      <section id="features" className="card-section">
        <h2>Explorez nos fonctionnalités </h2>
        <div className="feature-cards">
          <div className="card">
            <h3>Leçons interactives</h3>
            <p>Engagez vos étudiants avec un contenu dynamique et interactif.</p>
          </div>
          <div className="card">
            <h3>Outils de collaboration</h3>
            <p>Améliorez la collaboration entre les enseignants et les étudiants.</p>
          </div>
          <div className="card">
            <h3>Ressources complètes</h3>
            <p>Accédez à une vaste bibliothèque de ressources pédagogiques.</p>
          </div>
        </div>
      </section>

      <section id="testimonials" className="testimonial-section">
        <h2>Avis de nos utilisateurs</h2>
        <div className="testimonial">
          <p>"InstitShare a complètement transformé ma manière d'enseigner."</p>
          <cite>- Teacher A</cite>
        </div>
      </section>

      <footer>
        <div className="footer-content">
          <p>© 2024 InstitShare. Tous droits réservés.</p>
          <ul className="footer-links">
            <li><a href="#features">Fonctionnalités</a></li>
            <li><a href="#testimonials">Témoignages</a></li>
            <li><a href="#contact">Contact</a></li>
          </ul>
        </div>
      </footer>
    </div>
  );
};

export default Home;
