import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Post from './Post'; 
import { postService } from '../../services/post.service';

const PostPage = () => {
  const { postId } = useParams(); // Récupère l'ID du post depuis l'URL
  const [post, setPost] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchPost = async () => {
      try {
        const response = await postService.getPostById(postId);  
        setPost(response.data);
      } catch (error) {
        console.error('Error fetching post:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchPost();
  }, [postId]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!post) {
    return <div>Post not found</div>;
  }

  return (
    <div>
      <Post post={post} />
    </div>
  );
};

export default PostPage;
