import axios from 'axios';
import authHeader from './auth-header';

const API_URL = 'http://localhost:8080/api/';

const adminService = {
  requestModerator(userId, reason) {
    return axios.post(`${API_URL}moderator-requests/submit`, { userId, reason }, { headers: authHeader() });
  },
  //  manage moderators
  getPendingModeratorRequests() {
    return axios.get(`${API_URL}moderator-requests/pending`, { headers: authHeader() });
  },
  approveModeratorRequest(requestId) {
    return axios.patch(`${API_URL}moderator-requests/approve/${requestId}`, {}, { headers: authHeader() });
  },
  rejectModeratorRequest(requestId) {
    return axios.patch(`${API_URL}moderator-requests/reject/${requestId}`, {}, { headers: authHeader() });
  },
  // manage reports
  getReports() {
    return axios.get(`${API_URL}reports`, { headers: authHeader() });
  },
  resolveReport(reportId) {
    return axios.patch(`${API_URL}reports/${reportId}/resolve`, {}, { headers: authHeader() });
  },
  deletePost(postId) {
    return axios.delete(`${API_URL}moderator/posts/${postId}`, { headers: authHeader() });
  },
  deleteComment(commentId) {
    return axios.delete(`${API_URL}moderator/comments/${commentId}`, { headers: authHeader() });
  },
  deleteResource(resourceId) {
    return axios.delete(`${API_URL}moderator/resources/${resourceId}`, { headers: authHeader() });
  }
};

export default adminService;
