import axios from 'axios';
import authHeader from './auth-header';

const API_URL = 'http://localhost:8080/api/forums/';

export const forumService = {
  getCategories() {
    return axios.get(`${API_URL}categories`, { headers: authHeader() })
      .then(response => response.data);
  },

  getCategory(categoryId) {
    return axios.get(`${API_URL}categories/${categoryId}`, { headers: authHeader() })
      .then(response => response.data);
  },

  createCategory(category) {
    return axios.post(`${API_URL}categories`, category, { headers: authHeader() })
      .then(response => response.data);
  },

  getThreads(categoryId) {
    return axios.get(`${API_URL}threads/category/${categoryId}`, { headers: authHeader() })
      .then(response => response.data);
  },

  getThread(threadId) {
    return axios.get(`${API_URL}threads/${threadId}`, { headers: authHeader() })
      .then(response => response.data);
  },

  createThread(thread) {
    return axios.post(`${API_URL}threads`, thread, { headers: authHeader() })
      .then(response => response.data);
  },

  getPosts(threadId) {
    return axios.get(`${API_URL}posts/thread/${threadId}`, { headers: authHeader() })
      .then(response => response.data);
  },

  createPost(threadId, post) {
    return axios.post(`${API_URL}posts/thread/${threadId}`, post, { headers: authHeader() })
      .then(response => response.data);
  }
};
