import axios from 'axios';
import authHeader from './auth-header';

const API_URL = 'http://localhost:8080/api/';
const user = JSON.parse(localStorage.getItem('user'));

const getCurrentUser = () => {
    return user;
};

const getCurrentUserAsync = async () => {
    return new Promise((resolve, reject) => {
        const user = JSON.parse(localStorage.getItem('user'));
        if (user) {
            resolve(user);
        } else {
            reject("No user found");
        }
    });
};

export const dashboardService = {
    getCurrentUser,
    getCurrentUserAsync
};
