import React, { useState } from 'react';
import moment from 'moment';
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { postService } from "../../services/post.service";
import { IconButton, TextField, Button } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";


const Comment = ({ comment, currentUser, onDelete }) => {
  const queryClient = useQueryClient();
  const [isEditing, setIsEditing] = useState(false);
  const [editContent, setEditContent] = useState(comment.content);

  const deleteMutation = useMutation({
    mutationFn: () => postService.deleteComment(comment.id),
    onSuccess: () => {
      onDelete(); // appel du onDelete du component parent
    }
  });

  const updateMutation = useMutation({
    mutationFn: (updatedComment) => postService.updateComment(comment.id, updatedComment),
    onSuccess: () => {
      queryClient.invalidateQueries(['comments', comment.postId]);
      setIsEditing(false);
      onDelete();
    }
  });

  const handleDelete = () => {
    deleteMutation.mutate();
  };

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleSave = () => {
    if (editContent.trim()) {
      updateMutation.mutate({ content: editContent });
    }
  };

  return (
    <div className="comment">
      <div className="comment-header">
        <img
          src={comment.user.profilePictureUrl ? `http://localhost:8080${comment.user.profilePictureUrl}` : "/default-profile.png"}
          alt="Profile"
          className="comment-profile-picture"
        />
        <span className="comment-user">{comment.user.username}</span>
        <span className="comment-time">{moment(comment.createdAt).fromNow()}</span>
        {currentUser.id === comment.userId && (
          <>
          <IconButton onClick={handleEdit} size="small">
            <EditIcon />
          </IconButton>
          <IconButton onClick={handleDelete} size="small">
            <DeleteIcon />
          </IconButton>
        </>
        )}
      </div>
      <div className="comment-content">
        {isEditing ? (
          <>
            <TextField
              value={editContent}
              onChange={(e) => setEditContent(e.target.value)}
              fullWidth
            />
            <Button onClick={handleSave} variant="contained" color="primary">
              Save
            </Button>
          </>
        ) : (
          comment.content
        )}
      </div>
    </div>
  );
};

export default Comment;
