import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Paper, List, ListItem, ListItemText, Typography, Button } from '@mui/material';
import { forumService } from '../../services/forum.service';

const ForumCategoryList = () => {
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    const fetchCategories = async () => {
      const fetchedCategories = await forumService.getCategories();
      setCategories(fetchedCategories);
    };

    fetchCategories();
  }, []);

  return (
    <Paper sx={{ padding: 2 }}>
      <Typography variant="h4" component="h2" sx={{ marginBottom: 2 }}>
        Forum Categories
      </Typography>
      <List>
        {categories.map(category => (
          <ListItem key={category.id} component={Link} to={`/forums/category/${category.id}`} button>
            <ListItemText primary={category.name} secondary={category.description} />
          </ListItem>
        ))}
      </List>
      <Button variant="contained" color="primary" component={Link} to="/forums/create-category">
        Créer une nouvelle catégorie
      </Button>
    </Paper>
  );
};

export default ForumCategoryList;
