import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Paper, TextField, Button, Typography } from '@mui/material';
import { forumService } from '../../services/forum.service';

const CreateThread = () => {
  const { categoryId } = useParams();
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    const thread = { title, content, categoryId };
    await forumService.createThread(thread);
    navigate(`/forums/category/${categoryId}`);
  };

  return (
    <Paper sx={{ padding: 2 }}>
      <Typography variant="h4" component="h2" sx={{ marginBottom: 2 }}>
        Créer un nouveau thread
      </Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          label="Thread Title"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          fullWidth
          sx={{ marginBottom: 2 }}
        />
        <TextField
          label="Content"
          value={content}
          onChange={(e) => setContent(e.target.value)}
          fullWidth
          multiline
          rows={4}
          sx={{ marginBottom: 2 }}
        />
        <Button variant="contained" color="primary" type="submit">
          Créer
        </Button>
      </form>
    </Paper>
  );
};

export default CreateThread;
