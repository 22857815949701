import React, { useEffect, useState } from 'react';
import Feed from '../feed/Feed';
import LeftBar from '../leftBar/LeftBar';
import RightBar from '../rightBar/RightBar';
import { dashboardService } from '../../services/dashboard.service';
import "./Dashboard.scss";

const Dashboard = () => {
  const [currentUser, setCurrentUser] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const userData = await dashboardService.getCurrentUser();
        setCurrentUser(userData.data);
      } catch (error) {
        console.error('Failed to fetch data', error);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="dashboard">
      <div className="leftBar">
        <LeftBar currentUser={currentUser} />
      </div>
      <div className="feed">
        <Feed />
      </div>
      <div className="rightBar">
        <RightBar />
      </div>
    </div>
  );
};

export default Dashboard;
