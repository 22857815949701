import React, { useEffect, useState } from 'react';
import './RightBar.scss';
import { Avatar } from '@mui/material';
import { rightBarService } from "../../services/rightbar.service";
import UserProfileDialog from '../userProfileDialog/UserProfileDialog';


const RightBar = () => {
  const [suggestions, setSuggestions] = useState([]);
  const [onlineFriends, setOnlineFriends] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null); 
  const [dialogOpen, setDialogOpen] = useState(false); 

  useEffect(() => {
    const fetchData = async () => {
      try {
        const fetchedSuggestions = await rightBarService.getRecommendations();
        setSuggestions(fetchedSuggestions);
      } catch (error) {
        console.error('Échec de la récupération des suggestions pour RightBar:', error);
      }
    };

    fetchData();
  }, []);

  const handleFollow = async (userId) => {
    try {
      await rightBarService.followUser(userId);

      setSuggestions((prevSuggestions) =>
        prevSuggestions.map((user) =>
          user.id === userId ? { ...user, isFollowing: true } : user
        )
      );
    } catch (error) {
      console.error('Erreur lors du suivi de l\'utilisateur:', error);
    }
  };

  const handleDismiss = (userId) => {
    setSuggestions((prevSuggestions) =>
      prevSuggestions.filter(user => user.id !== userId)
    );
  };

  const handleOpenDialog = (user) => {
    setSelectedUser(user);
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
    setSelectedUser(null);
  };

  useEffect(() => {
    const fetchOnlineFriends = async () => {
      try {
        const fetchedOnlineFriends = await rightBarService.getOnlineFriends();
        setOnlineFriends(fetchedOnlineFriends);
      } catch (error) {
        console.error('Échec de la récupération des amis en ligne:', error);
      }
    };

    fetchOnlineFriends();
  }, []);

  return (
    <div className="right-bar">
      <div className="content-section">
        <h4>Suggestions pour vous</h4>
        {suggestions.map(user => (
          <div key={user.id} className="user-card">
            <div className="user-info">
              <Avatar 
                src={user.profilePictureUrl ? `http://localhost:8080${user.profilePictureUrl}` : "/default-profile.png"}
                alt={user.username} 
                onClick={() => handleOpenDialog(user)}
                style={{ cursor: 'pointer' }}
              />
              <span onClick={() => handleOpenDialog(user)} style={{ cursor: 'pointer' }}>{user.username}</span>
            </div>
            <div className="action-buttons">
              {user.isFollowing ? (
                <button>Suivi</button>
              ) : (
                <button onClick={() => handleFollow(user.id)}>Suivre</button>
              )}
              <button onClick={() => handleDismiss(user.id)}>Ignorer</button>
            </div>
          </div>
        ))}

        <h4>Amis en ligne</h4>
        {onlineFriends.length > 0 ? (
          onlineFriends.map(friend => (
            <div key={friend.id} className="friend-card">
              <div className="friend-info">
                <Avatar 
                  src={friend.profilePictureUrl ? `http://localhost:8080${friend.profilePictureUrl}` : "/default-profile.png"}
                  alt={friend.username}
                  onClick={() => handleOpenDialog(friend)} 
                  style={{ cursor: 'pointer' }}
                />
                <span onClick={() => handleOpenDialog(friend)} style={{ cursor: 'pointer' }}>{friend.username}</span>
                <div className="online-indicator"></div>
              </div>
              <div className="action-buttons">
                <button>Envoyer un message</button>
              </div>
            </div>
          ))
        ) : (
          <p>Aucun ami en ligne pour le moment.</p>
        )}
      </div>

      <UserProfileDialog open={dialogOpen} onClose={handleCloseDialog} user={selectedUser} />
    </div>
  );
};

export default RightBar;
