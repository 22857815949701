import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { ressourceService } from '../services/ressource.service';
import { matiereService } from '../services/matiere.service';

export const fetchAllRessources = createAsyncThunk(
    'ressources/fetchAll',
    async () => {
        const response = await ressourceService.getAllRessources();
        return response.data;
    }
);

const initialState = {
    ressources: [],
    isLoading: false,
    error: null
};

const ressourceSlice = createSlice({
    name: 'ressources',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllRessources.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(fetchAllRessources.fulfilled, (state, action) => {
                state.ressources = action.payload;
                state.isLoading = false;
            })
            .addCase(fetchAllRessources.rejected, (state, action) => {
                state.error = action.error.message;
                state.isLoading = false;
            });
    }
});

export default ressourceSlice.reducer;
