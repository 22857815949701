import React from 'react';
import './LeftBar.scss';
import { useSelector } from 'react-redux';
import HomeIcon from '@mui/icons-material/Home';
import SchoolIcon from '@mui/icons-material/School';
import FolderSharedIcon from '@mui/icons-material/FolderShared';
import EventIcon from '@mui/icons-material/Event';
import ForumIcon from '@mui/icons-material/Forum';
import VideoLibraryIcon from '@mui/icons-material/VideoLibrary';
import { Avatar } from '@mui/material';

const LeftBar = () => {
  const currentUser = useSelector((state) => state.auth.user);

  const links = [
    { name: 'Home', url: '/home', icon: <HomeIcon /> },
    { name: 'Classes', url: '/classes', icon: <SchoolIcon /> },
    { name: 'Ressources', url: '/ressources', icon: <FolderSharedIcon /> },
    { name: 'Events', url: '/events', icon: <EventIcon /> },
    { name: 'Forums', url: '/forums', icon: <ForumIcon /> },
    { name: 'Videos', url: '/videos', icon: <VideoLibraryIcon /> }
  ];

  if (!currentUser) {
    return <div>Loading...</div>;
  }

  return (
    <div className="left-bar">
      <div className="user-profile">
        <Avatar src={currentUser.profilePictureUrl ? `http://localhost:8080${currentUser.profilePictureUrl}` : "/default-profile.png"} alt={currentUser.name} />
        <span>{currentUser.username}</span>
      </div>
      <ul className="navigation-links">
        {links.map(link => (
          <li key={link.name}>
            <a href={link.url}>
              {link.icon}
              <span>{link.name}</span>
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default LeftBar;
