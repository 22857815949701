import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAllMatieres } from '../../slices/matiereSlice';
import './MatiereList.css';

const MatiereList = () => {
    const dispatch = useDispatch();
    const { matieres, isLoading, error } = useSelector((state) => state.matieres);

    useEffect(() => {
        dispatch(fetchAllMatieres());
    }, [dispatch]);

    if (isLoading) return <div>Loading...</div>;
    if (error) return <div>Error: {error}</div>;

    return (
        <div>
            <h1>Liste des Matières</h1>
            <div className="matiere-list">
                {matieres.map((matiere) => (
                    <div key={matiere.id} className="matiere-card">
                        <img src={matiere.image} alt={matiere.name} />
                        <h3>{matiere.name}</h3>
                        <p>{matiere.description}</p>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default MatiereList;
