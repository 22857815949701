import React, { useState, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Routes, Route, Link, useLocation } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import Login from "./components/login/Login";
import Register from "./components/register/Register";
import Home from "./components/home/Home";
import { logout } from "./slices/auth";
import Profile from "./components/profile/Profile";
import EditProfile from "./components/editProfile/EditProfile";
import MatiereList from "./components/matiereList/MatiereList";
import EspaceRessource from "./components/espaceRessource/EspaceRessource";
import NotificationsIcon from '@mui/icons-material/Notifications';
import MailIcon from '@mui/icons-material/Mail';
import Popover from '@mui/material/Popover';
import Badge from '@mui/material/Badge';
import Dashboard from "./components/dashboard/Dashboard";
import { messageService } from "./services/message.service";
import { notificationService } from "./services/notification.service";
import MessageList from './components/messages/MessageList';
import MessageDetail from './components/messages/MessageDetail';
import ForumCategoryList from './components/forums/ForumCategoryList';
import PostPage from './components/post/PostPage';
import ForumPostList from './components/forums/ForumPostList';
import ForumThreadList from './components/forums/ForumThreadList';
import CreatePost from './components/forums/CreatePost';
import CreateThread from './components/forums/CreateThread';
import CreateCategory from './components/forums/CreateCategory';
import NotificationList from './components/notifications/NotificationList';
import ManageModerators from './components/admin/ManageModerators';
import ManageReports from './components/report/ManageReports';
import ErrorBoundary from "./components/ErrorBoundary";

const Main = () => {
  const location = useLocation();
  const { user: currentUser } = useSelector(state => state.auth);
  const dispatch = useDispatch();

  const [messageAnchor, setMessageAnchor] = useState(null);
  const [notificationAnchor, setNotificationAnchor] = useState(null);
  const [discussions, setDiscussions] = useState([]);
  const [notifications, setNotifications] = useState([]);
  const [unreadDiscussionsCount, setUnreadDiscussionsCount] = useState(0);
  const [unreadNotificationCount, setUnreadNotificationCount] = useState(0);

  const logOut = useCallback(() => {
    dispatch(logout());
  }, [dispatch]);

  const handleMessageClick = async (event) => {
    setMessageAnchor(event.currentTarget);
    if (currentUser) {
      const fetchedDiscussions = await messageService.getDiscussions(currentUser.id);
      setDiscussions(fetchedDiscussions);
    }
  };

  const handleNotificationClick = async (event) => {
    setNotificationAnchor(event.currentTarget);
    if (currentUser) {
      const fetchedNotifications = await notificationService.getUserNotifications(currentUser.id);
      setNotifications(fetchedNotifications);
      const unreadCount = fetchedNotifications.filter(notification => !notification.isRead).length;
      setUnreadNotificationCount(unreadCount);
    }
  };

  const handleClose = () => {
    setMessageAnchor(null);
    setNotificationAnchor(null);
  };

  useEffect(() => {
    if (currentUser) {
      const fetchUnreadCount = async () => {
        const fetchedDiscussions = await messageService.getDiscussions(currentUser.id);
        const unreadCount = fetchedDiscussions.filter(
          discussion => discussion.lastMessage && !discussion.lastMessage.isRead && discussion.lastMessage.senderId !== currentUser.id
        ).length;
        setUnreadDiscussionsCount(unreadCount);

        const fetchedNotifications = await notificationService.getUnreadNotifications(currentUser.id);
        setUnreadNotificationCount(fetchedNotifications.data.length);
      };

      fetchUnreadCount();

      const intervalId = setInterval(fetchUnreadCount, 3000);

      return () => clearInterval(intervalId);
    }
  }, [currentUser]);

  const showBackground = location.pathname === '/login' || location.pathname === '/register';

  return (
    <div>
      {showBackground && <div className="app-background"></div>}
      <nav className="navbar navbar-expand navbar-dark navbar-custom">
        <Link to="/" className="navbar-brand">institShare</Link>
        <div className="navbar-nav mr-auto">
          {!currentUser && (
            <li className="nav-item">
              <Link to="/home" className="nav-link">Accueil</Link>
            </li>)}
          {currentUser && (
            <>
              <li className="nav-item">
                <Link to="/dashboard" className="nav-link">Accueil</Link>
              </li>
              <li className="nav-item">
                <Link to="/profile" className="nav-link">Profile</Link>
              </li>
              <li className="nav-item">
                <Link to="/ressources" className="nav-link">Espace ressources</Link>
              </li>
              {currentUser.roles.includes("ROLE_ADMIN") && (
                <li className="nav-item">
                  <Link to="/admin" className="nav-link">Admin Panel</Link>
                </li>
              )}
              {currentUser.roles.includes("ROLE_MODERATOR") && (
                <li className="nav-item">
                  <Link to="/reports" className="nav-link">Reports</Link>
                </li>
              )}
              <li className="nav-item icon-button" onClick={handleMessageClick} aria-label="Open messages">
                <Badge badgeContent={unreadDiscussionsCount} color="secondary">
                  <MailIcon />
                </Badge>
              </li>
              <Popover
                open={Boolean(messageAnchor)}
                anchorEl={messageAnchor}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
              >
                <ErrorBoundary>
                  <MessageList discussions={discussions} />
                </ErrorBoundary>
              </Popover>
              <li className="nav-item icon-button" onClick={handleNotificationClick} aria-label="Open notifications">
                <Badge badgeContent={unreadNotificationCount} color="secondary">
                  <NotificationsIcon />
                </Badge>
              </li>
              <Popover
                open={Boolean(notificationAnchor)}
                anchorEl={notificationAnchor}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
              >
                <ErrorBoundary>
                  <NotificationList notifications={notifications} />
                </ErrorBoundary>
              </Popover>
            </>
          )}
        </div>
        {currentUser ? (
          <div className="navbar-nav ml-auto">
            <li className="nav-item">
              <a href="/login" className="nav-link" onClick={logOut}>Se déconnecter</a>
            </li>
          </div>
        ) : (
          <div className="navbar-nav ml-auto">
            <li className="nav-item">
              <Link to="/login" className="nav-link">Se connecter</Link>
            </li>
            <li className="nav-item">
              <Link to="/register" className="nav-link">Créer un compte</Link>
            </li>
          </div>
        )}
      </nav>
      <div className="container mt-3">
        <ErrorBoundary>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/home" element={currentUser ? <Dashboard /> : <Home />} />
            <Route path="/home" element={<Home />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/matiere" element={<MatiereList />} />
            <Route path="/ressources" element={<EspaceRessource />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/message/:id" element={<MessageDetail />} />
            <Route path="/edit-profile" element={<EditProfile />} />
            <Route path="/forums" element={<ForumCategoryList />} />
            <Route path="/forums/category/:categoryId" element={<ForumThreadList />} />
            <Route path="/forums/thread/:threadId" element={<ForumPostList />} />
            <Route path="/forums/create-category" element={<CreateCategory />} />
            <Route path="/forums/category/:categoryId/create-thread" element={<CreateThread />} />
            <Route path="/forums/thread/:threadId/create-post" element={<CreatePost />} />
            <Route path="/post/:postId" element={<PostPage />} />
            <Route path="/profile/:id" element={<Profile />} />
            {currentUser && currentUser.roles.includes("ROLE_ADMIN") && (
              <Route path="/admin" element={<ManageModerators />} />
            )}
            {currentUser && currentUser.roles.includes("ROLE_MODERATOR") && (
              <Route path="/reports" element={<ManageReports />} />
            )}
          </Routes>
        </ErrorBoundary>
      </div>
    </div>
  );
};

export default Main;
