import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import "./Post.scss";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { postService } from "../../services/post.service";
import { dashboardService } from "../../services/dashboard.service";
import {
  FavoriteBorderOutlined as UnlikedIcon,
  Favorite as LikedIcon,
  ShareOutlined as ShareIcon,
  MoreHoriz as OptionsIcon
} from "@mui/icons-material";
import moment from "moment";
import CommentList from "../comment/CommentList";

const Post = ({ post }) => {
  const [optionsVisible, toggleOptions] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const [comment, setComment] = useState("");
  const queryClient = useQueryClient();

  useEffect(() => {
    dashboardService.getCurrentUserAsync()
      .then(user => setCurrentUser(user))
      .catch(err => console.error(err));
  }, []);

  const { data: likesData, isLoading: likesLoading } = useQuery({
    queryKey: ['likes', post.id],
    queryFn: () => postService.getLikes(post.id)
  });

  const likeMutation = useMutation({
    mutationFn: () => {
      const isLiked = likesData?.some(like => like.userId === currentUser.id);
      return isLiked ? postService.unlikePost(post.id) : postService.likePost(post.id);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['likes', post.id]);
    }
  });

  const deleteMutation = useMutation({
    mutationFn: () => postService.deletePost(post.id),
    onSuccess: () => {
      queryClient.invalidateQueries(["posts"]);
      alert('Post deleted successfully');
    }
  });


  if (!currentUser) {
    return <div>Loading...</div>;
  }

  return (
    <div className="post-card">
      <div className="post-header">
        <div className="user-info">
          <img src={post.user.profilePictureUrl ? `http://localhost:8080${post.user.profilePictureUrl}` : "/default-profile.png"} alt="Profile" />
          <div className="user-details">
            <Link to={`/profile/${post.userId}`} className="user-name">
              {post.user.username} a posté :
            </Link>
            <span className="post-time">{moment(post.createdAt).fromNow()}</span>
          </div>
        </div>
        <OptionsIcon onClick={() => toggleOptions(!optionsVisible)} />
        {optionsVisible && (
          <button className="delete-button" onClick={() => deleteMutation.mutate()}>Delete</button>
        )}
      </div>
      <div className="post-content">
        {post.title && <h3 className="post-title">{post.title}</h3>}
        <p className="post-description">{post.content}</p>
      </div>
      {post.imageUrl && <img className="post-image" src={`http://localhost:8080${post.imageUrl}`} alt="" />}
      <div className="post-actions">
        <div onClick={() => likeMutation.mutate()}>
          {likesLoading ? "Loading..." : likesData?.some(like => like.userId === currentUser.id) ? <LikedIcon color="error" /> : <UnlikedIcon />}
          {likesData?.length || 0} Likes
        </div>
        <div>
          <ShareIcon /> Share
        </div>
      </div>
      <div className="post-comments">
        <CommentList postId={post.id} />
      </div>
    </div>
  );
};

export default Post;
