import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Paper, List, ListItem, ListItemText, Typography, Button, TextField } from '@mui/material';
import { forumService } from '../../services/forum.service';

const ForumPostList = () => {
  const { threadId } = useParams();
  const [posts, setPosts] = useState([]);
  const [newPostContent, setNewPostContent] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        setLoading(true);
        const fetchedPosts = await forumService.getPosts(threadId);
        setPosts(fetchedPosts);
      } catch (err) {
        console.error("Error fetching posts:", err);
        setError(err.message || "An error occurred while fetching posts.");
      } finally {
        setLoading(false);
      }
    };

    fetchPosts();
  }, [threadId]);

  const handleCreatePost = async () => {
    try {
      await forumService.createPost(threadId, { content: newPostContent });
      setNewPostContent('');
      const fetchedPosts = await forumService.getPosts(threadId);
      setPosts(fetchedPosts);
    } catch (err) {
      console.error("Error creating post:", err);
      setError(err.message || "An error occurred while creating the post.");
    }
  };

  if (loading) {
    return <Typography>Loading...</Typography>;
  }

  if (error) {
    return <Typography color="error">Error: {error}</Typography>;
  }

  return (
    <Paper sx={{ padding: 2 }}>
      <Typography variant="h4" component="h2" sx={{ marginBottom: 2 }}>
        Forum Posts
      </Typography>
      <List>
        {posts.map(post => (
          <ListItem key={post.id}>
            <ListItemText primary={post.content} secondary={`By ${post.creator?.username}`} />
          </ListItem>
        ))}
      </List>
      <TextField
        label="New Post"
        multiline
        rows={4}
        value={newPostContent}
        onChange={(e) => setNewPostContent(e.target.value)}
        variant="outlined"
        fullWidth
        sx={{ marginBottom: 2 }}
      />
      <Button variant="contained" color="primary" onClick={handleCreatePost}>
        Post
      </Button>
    </Paper>
  );
};

export default ForumPostList;
