import React, { useState } from "react";
import { Navigate, Link } from 'react-router-dom';
import { useSelector } from "react-redux";
import Typography from '@mui/material/Typography';
import { Card, ListGroup, Button, Image, Tab, Nav, Row, Col, Container, Form } from 'react-bootstrap';
import adminService from '../../services/admin.service';
import './Profile.scss';

const Profile = () => {
  const { user: currentUser } = useSelector((state) => state.auth);
  const [requestMessage, setRequestMessage] = useState('');
  const [moderatorReason, setModeratorReason] = useState('');

  if (!currentUser) {
    return <Navigate to="/login" />;
  }

  const profilePictureUrl = currentUser.profilePictureUrl || "/default-profile.png";

  const handleRequestModerator = async () => {
    try {
        await adminService.requestModerator(currentUser.id, moderatorReason);
        setRequestMessage('Your request has been submitted.');
    } catch (error) {
        setRequestMessage('There was an error submitting your request.');
    }
};

  return (
    <Container fluid className="profile-container">
      <Row>
        <Col md={4} className="sidebar">
          <Card className="profile-card">
            <Card.Header as="h5">Profil de {currentUser.username}</Card.Header>
            <div className="text-center p-2">
              <Image src={profilePictureUrl} roundedCircle fluid className="profile-image" />
            </div>
            <Card.Body className="text-center">
              <h6>{currentUser.fullName || "Nom complet non spécifié"}</h6>
              <p>{currentUser.bio || "Biographie non spécifiée"}</p>
              <Link to="/edit-profile">
                <Button variant="primary">Modifier le Profil</Button>
              </Link>
            </Card.Body>
          </Card>
        </Col>
        <Col md={8} className="main-content">
          <Tab.Container defaultActiveKey="info">
            <Nav variant="tabs">
              <Nav.Item>
                <Nav.Link eventKey="info">Informations Personnelles</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="preferences">Préférences</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="activity">Historique des Activités</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="moderator-request">Demander à devenir modérateur</Nav.Link>
              </Nav.Item>
            </Nav>
            <Tab.Content>
              <Tab.Pane eventKey="info" className="p-3">
                <Card>
                  <Card.Body>
                    <h5>Informations Personnelles</h5>
                    <ListGroup className="list-group-flush">
                      <ListGroup.Item><strong>ID:</strong> {currentUser.id}</ListGroup.Item>
                      <ListGroup.Item><strong>Email:</strong> {currentUser.email}</ListGroup.Item>
                      <ListGroup.Item>
                        <strong>Roles:</strong>
                        <ul>
                          {currentUser.roles && currentUser.roles.map((role, index) => <li key={index}>{role}</li>)}
                        </ul>
                      </ListGroup.Item>
                      <ListGroup.Item><strong>Spécialité :</strong> {currentUser.specialty || "Non spécifié"}</ListGroup.Item>
                      <ListGroup.Item><strong>Date de naissance :</strong> {currentUser.birthDate || "Non spécifié"}</ListGroup.Item>
                    </ListGroup>
                  </Card.Body>
                </Card>
              </Tab.Pane>
              <Tab.Pane eventKey="preferences" className="p-3">
                <Card>
                  <Card.Body>
                    <h5>Préférences</h5>
                    <ListGroup className="list-group-flush">
                      <ListGroup.Item><strong>Langue Préférée :</strong> {currentUser.language || "Non spécifié"}</ListGroup.Item>
                      <ListGroup.Item><strong>Notifications :</strong> {currentUser.notifications ? "Activées" : "Désactivées"}</ListGroup.Item>
                    </ListGroup>
                  </Card.Body>
                </Card>
              </Tab.Pane>
              <Tab.Pane eventKey="activity" className="p-3">
                <Card>
                  <Card.Body>
                    <h5>Historique des Activités</h5>
                    <ListGroup className="list-group-flush">
                      <ListGroup.Item><strong>Dernière Connexion :</strong> {currentUser.lastLogin || "Non spécifié"}</ListGroup.Item>
                      <ListGroup.Item><strong>Activités Récentes :</strong>
                        <ul>
                          {currentUser.recentActivities && currentUser.recentActivities.map((activity, index) => <li key={index}>{activity}</li>)}
                        </ul>
                      </ListGroup.Item>
                    </ListGroup>
                  </Card.Body>
                </Card>
              </Tab.Pane>
              <Tab.Pane eventKey="moderator-request" className="p-3">
                <Card>
                  <Card.Body>
                    <h5>Demande de Modérateur</h5>
                    <Form>
                      <Form.Group controlId="moderatorReason">
                        <Form.Label>Raison pour devenir modérateur</Form.Label>
                        <Form.Control
                          as="textarea"
                          rows={3}
                          value={moderatorReason}
                          onChange={(e) => setModeratorReason(e.target.value)}
                        />
                      </Form.Group>
                      <Button variant="primary" onClick={handleRequestModerator}>
                        Soumettre la demande
                      </Button>
                    </Form>
                    {requestMessage && <Typography sx={{ marginTop: 2 }}>{requestMessage}</Typography>}
                  </Card.Body>
                </Card>
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </Col>
      </Row>
    </Container>
  );
};

export default Profile;
