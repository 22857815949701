import React from 'react';
import Post from "../post/Post";
import Share from "../share/Share";
import "./Feed.scss";
import { useQuery } from "@tanstack/react-query";
import { postService } from '../../services/post.service';

const Feed = ({ userId }) => {
  const fetchPosts = () => postService.fetchAllPosts(); //postService.fetchPostsForUser(userId);
  // const fetchPosts = () => postService.fetchAllPosts().then(res => {
  //   console.log("Posts data:", res.data);
  //   return res.data.posts;
  // });

  const { data, isLoading, error } = useQuery({
    queryKey: ["feed"], //queryKey: ["feed", userId],
    queryFn: fetchPosts
  });

  const posts = data?.data;

  return (
    <div className="feed">
      <Share />
      {error ? <div>Something went wrong!</div> :
       isLoading ? <div>Loading...</div> :
       posts?.map(post => <Post key={post.id} post={post} />)}
    </div>
  );
};

export default Feed;