import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { postService } from "../../services/post.service";
import { IconButton, Button, TextField, Avatar, Tooltip } from "@mui/material";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import LocationOn from "@mui/icons-material/LocationOn";
import PersonAdd from "@mui/icons-material/PersonAdd";
import "./Share.scss";

const Share = () => {
  const [file, setFile] = useState(null);
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [preview, setPreview] = useState(false);

  const { user: currentUser } = useSelector((state) => state.auth);

  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: (newPost) => postService.createPost(newPost),
    onSuccess: () => {
      queryClient.invalidateQueries(["feed"]);
    },
  });

  const handleClick = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("title", title);
    formData.append("content", content);
    if (file) {
      formData.append("file", file);
    }

    mutation.mutate(formData);

    setTitle("");
    setContent("");
    setFile(null);
  };

  const handlePreview = () => {
    setPreview(!preview);
  };

  return (
    <div className="share">
      <div className="container">
        <div className="top">
          <div className="left">
            <Avatar
              src={
                currentUser.profilePictureUrl
                  ? `http://localhost:8080${currentUser.profilePictureUrl}`
                  : "/default-profile.png"
              }
              alt=""
              sx={{ width: 56, height: 56 }}
            />
            <div className="input-container">
              <TextField
                label="Quel est le sujet ?"
                variant="outlined"
                fullWidth
                margin="normal"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                placeholder="Titre de votre partage..."
              />
              <TextField
                label="Qu'avez-vous envie de partager avec les autres ?"
                variant="outlined"
                fullWidth
                multiline
                rows={4}
                margin="normal"
                value={content}
                onChange={(e) => setContent(e.target.value)}
                placeholder="Racontez une histoire, partagez une idée, ou posez une question..."
              />
            </div>
          </div>
          <div className="right">
            {file && (
              <div className="file-preview-container">
                <img
                  className="file-preview"
                  alt="Aperçu du fichier"
                  src={URL.createObjectURL(file)}
                />
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={() => setFile(null)}
                  className="remove-file-btn"
                >
                  Supprimer
                </Button>
              </div>
            )}
          </div>
        </div>
        <hr />
        <div className="bottom">
          <div className="left">
            <input
              type="file"
              id="file"
              style={{ display: "none" }}
              onChange={(e) => setFile(e.target.files[0])}
            />
            <label htmlFor="file">
              <Tooltip title="Ajouter une photo">
                <IconButton color="primary" aria-label="upload picture" component="span">
                  <PhotoCamera />
                </IconButton>
              </Tooltip>
            </label>
            <Tooltip title="Ajouter votre emplacement">
              <IconButton color="primary" aria-label="add location">
                <LocationOn />
              </IconButton>
            </Tooltip>
            <Tooltip title="Taguer des amis">
              <IconButton color="primary" aria-label="tag friends">
                <PersonAdd />
              </IconButton>
            </Tooltip>
          </div>
          <div className="right">
            <Button variant="outlined" color="primary" onClick={handlePreview}>
              {preview ? "Masquer la prévisualisation" : "Prévisualiser"}
            </Button>
            <Button variant="contained" color="primary" onClick={handleClick}>
              Partager
            </Button>
          </div>
        </div>

        {preview && (
          <div className="preview-section">
            <h3>Prévisualisation de votre partage</h3>
            <div className="preview-title">{title}</div>
            <div className="preview-content">{content}</div>
            {file && <img className="preview-image" alt="Aperçu" src={URL.createObjectURL(file)} />}
          </div>
        )}
      </div>
    </div>
  );
};

export default Share;
