import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchAllRessources } from '../../slices/ressourceSlice';
import { fetchAllMatieres } from '../../slices/matiereSlice';
import {
  Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
  TextField, Button, Select, MenuItem, FormControl, InputLabel, Checkbox,
  ListItemText, OutlinedInput, Typography
} from '@mui/material';
import AddRessourceDialog from '../addRessourceDialog/AddRessourceDialog';
import './EspaceRessource.scss';

const cycles = ['P1-P2', 'P3-P4', 'P5-P6'];
const types = ['Exercice', 'Évaluation'];

function EspaceRessource() {
  const { ressources, isLoading, error } = useSelector((state) => state.ressources);
  const { matieres } = useSelector((state) => state.matieres);
  const dispatch = useDispatch();
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [searchTerm, setSearchTerm] = React.useState('');
  const [matiere, setMatiere] = React.useState([]);
  const [cycle, setCycle] = React.useState('');
  const [type, setType] = React.useState([]);

  React.useEffect(() => {
    dispatch(fetchAllRessources());
    dispatch(fetchAllMatieres());
  }, [dispatch]);

  const handleSearchChange = (event) => setSearchTerm(event.target.value.toLowerCase());
  const handleMatiereChange = (event) => setMatiere(event.target.value);
  const handleCycleChange = (event) => setCycle(event.target.value);
  const handleTypeChange = (event) => setType(event.target.value);
  const handleOpenDialog = () => {
    setDialogOpen(true);
  };
  const handleCloseDialog = () => {
    setDialogOpen(false);
    dispatch(fetchAllRessources()); // Refresh resources
  };

  // Fonction pour ouvrir le fichier
  const handleOpenFile = (fileUrl) => {
    window.open(`http://localhost:8080${fileUrl}`, '_blank');
  };

  // Filtrer les ressources en fonction des termes de recherche et des filtres
  const filteredRessources = ressources.filter((ressource) => {
    const searchLower = searchTerm.toLowerCase();
    const nomMatch = ressource.nom.toLowerCase().includes(searchLower);
    const matiereMatch = ressource.matiere.toLowerCase().includes(searchLower);
    const cycleMatch = ressource.cycleEnseignement.toLowerCase().includes(searchLower);
    const typeMatch = ressource.type.toLowerCase().includes(searchLower);

    const searchTermMatch = nomMatch || matiereMatch || cycleMatch || typeMatch;

    return (
      searchTermMatch &&
      (matiere.length === 0 || matiere.includes(ressource.matiere)) &&
      (cycle === '' || ressource.cycleEnseignement === cycle) &&
      (type.length === 0 || type.includes(ressource.type))
    );
  });

  if (isLoading) return <Typography>Loading resources...</Typography>;
  if (error) return <Typography>Error: {error}</Typography>;

  return (
    <div>
      <div className="filterContainer">
        <TextField
          id="search"
          label="Recherche"
          variant="outlined"
          value={searchTerm}
          onChange={handleSearchChange}
          className="searchInput"
        />
        <FormControl className="formControl">
          <InputLabel id="matiere-label">Matière</InputLabel>
          <Select
            labelId="matiere-label"
            id="matiere"
            multiple
            value={matiere}
            onChange={handleMatiereChange}
            input={<OutlinedInput label="Matière" />}
            renderValue={(selected) => selected.join(', ')}
            MenuProps={{
              PaperProps: {
                className: 'menuProps'
              }
            }}
          >
            {matieres && matieres.map((matiereItem) => (
              <MenuItem key={matiereItem.id} value={matiereItem.name}>
                <Checkbox checked={matiere.indexOf(matiereItem.name) > -1} />
                <ListItemText primary={matiereItem.name} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl className="formControl">
          <InputLabel id="cycle-label">Cycle</InputLabel>
          <Select
            labelId="cycle-label"
            id="cycle"
            value={cycle}
            onChange={handleCycleChange}
          >
            {cycles.map((name, index) => (
              <MenuItem key={index} value={name}>{name}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl className="formControl">
          <InputLabel id="type-label">Type</InputLabel>
          <Select
            labelId="type-label"
            id="type"
            multiple
            value={type}
            onChange={handleTypeChange}
            input={<OutlinedInput label="Type" />}
            renderValue={(selected) => selected.join(', ')}
            MenuProps={{
              PaperProps: {
                className: 'menuProps'
              }
            }}
          >
            {types.map((name, index) => (
              <MenuItem key={index} value={name}>
                <Checkbox checked={type.indexOf(name) > -1} />
                <ListItemText primary={name} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Button variant="contained" color="primary" onClick={handleOpenDialog}>Ajouter Ressource</Button>
        <AddRessourceDialog open={dialogOpen} onClose={handleCloseDialog} />
      </div>
      <TableContainer component={Paper}>
        <Table className="table" aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Nom</TableCell>
              <TableCell align="right">Matières</TableCell>
              <TableCell align="right">Date</TableCell>
              <TableCell align="right">Cycle</TableCell>
              <TableCell align="right">Type</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredRessources.map((ressource) => (
              <TableRow key={ressource.id}>
                <TableCell
                  component="th"
                  scope="row"
                  className="tableCellClickable"
                  onClick={() => handleOpenFile(ressource.url)}
                >
                  {ressource.nom}
                </TableCell>
                <TableCell align="right">{ressource.matiere}</TableCell>
                <TableCell align="right">{ressource.dateCreation ? ressource.dateCreation : 'Non spécifié'}</TableCell>
                <TableCell align="right">{ressource.cycleEnseignement}</TableCell>
                <TableCell align="right">{ressource.type}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

export default EspaceRessource;
