import React, { useState, useEffect } from 'react';
import { notificationService } from '../../services/notification.service';
import { useSelector } from 'react-redux';
import { List, ListItem, ListItemText, Paper, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom'; // Importer useNavigate pour la redirection
import './NotificationList.scss';

const NotificationList = () => {
  const { user: currentUser } = useSelector((state) => state.auth);
  const [notifications, setNotifications] = useState([]);
  const navigate = useNavigate(); // Déclare useNavigate

  useEffect(() => {
    if (currentUser) {
      notificationService.getUserNotifications(currentUser.id)
        .then(response => {
          setNotifications(response.data);
        })
        .catch(error => {
          console.error("Error fetching notifications", error);
        });
    }
  }, [currentUser]);

  // Fonction pour gérer la redirection lorsqu'on clique sur une notification
  const handleNotificationClick = (notification) => {
    if (notification.postId) {
      navigate(`/post/${notification.postId}`); // Redirige vers la page du post
    } else {
      console.log("No link associated with this notification");
    }
  };

  return (
    <Paper className="notification-paper">
      <Typography variant="h6" className="notification-title">Notifications</Typography>
      <List>
        {notifications.map((notification) => (
          <ListItem
            key={notification.id}
            className={`notification-item ${notification.isRead ? 'read' : 'unread'}`}
            onClick={() => handleNotificationClick(notification)} // Appelle la fonction de redirection
            style={{ cursor: 'pointer' }} // Change le curseur pour indiquer que c'est cliquable
          >
            <ListItemText
              primary={
                <Typography variant="body1" className="notification-content">
                  {notification.type}
                </Typography>
              }
              secondary={
                <Typography variant="body2" className="notification-content">
                  {notification.content}
                </Typography>
              }
            />
          </ListItem>
        ))}
      </List>
    </Paper>
  );
};

export default NotificationList;
