import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { List, ListItem, ListItemText, ListItemAvatar, Avatar, Typography, Paper, Button, TextField, Autocomplete } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { messageService } from '../../services/message.service';
import { dashboardService } from '../../services/dashboard.service';
import moment from 'moment';
import './MessageList.scss';

const MessageList = ({ discussions }) => {
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [userSearchQuery, setUserSearchQuery] = useState("");
  const [userOptions, setUserOptions] = useState([]);
  const queryClient = useQueryClient();
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    dashboardService.getCurrentUserAsync()
      .then(user => {
        setCurrentUser(user);
        setLoading(false);
      })
      .catch(err => {
        console.error(err);
        setLoading(false);
      });
  }, []);

  const searchMutation = useMutation({
    mutationFn: (query) => messageService.searchUsers(query),
    onSuccess: (data) => setUserOptions(data),
  });

  const handleUserSearch = (event) => {
    setUserSearchQuery(event.target.value);
    if (event.target.value.trim() !== "") {
      searchMutation.mutate(event.target.value);
    } else {
      setUserOptions([]);
    }
  };

  const createDiscussionMutation = useMutation({
    mutationFn: (newDiscussion) => messageService.createDiscussion(newDiscussion),
    onSuccess: () => queryClient.invalidateQueries(['discussions']),
  });

  const handleCreateDiscussion = () => {
    if (selectedUsers.length === 0) return;
    const participantIds = selectedUsers.map(user => user.id);
    createDiscussionMutation.mutate({ title: "New Discussion", participantIds });
    setSelectedUsers([]);
    setUserSearchQuery("");
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <Paper>
      <Autocomplete
        multiple
        options={userOptions}
        getOptionLabel={(option) => option.username}
        value={selectedUsers}
        onChange={(event, newValue) => setSelectedUsers(newValue)}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Rechercher un utilisateur"
            variant="outlined"
            fullWidth
            value={userSearchQuery}
            onChange={handleUserSearch}
          />
        )}
      />
      <Button variant="contained" color="primary" onClick={handleCreateDiscussion}>
        Créer une discussion
      </Button>
      <List>
        {discussions.map((discussion) => {
          const lastMessage = discussion.lastMessage;
          const isRead = lastMessage ? lastMessage.isRead || lastMessage.senderId === currentUser.id : false;
          return (
            <ListItem
              key={discussion.id}
              component={Link}
              to={`/message/${discussion.id}`}
              button
              className={isRead ? 'read' : 'unread'}
            >
              <ListItemAvatar>
                <Avatar>{discussion.title.charAt(0).toUpperCase()}</Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={discussion.title}
                secondary={
                  lastMessage ? (
                    <>
                      <Typography component="span" variant="body2" color="textPrimary">
                        {lastMessage.sender.username}
                      </Typography>
                      {" — "}
                      <Typography component="span" variant="body2" color="textSecondary">
                        {lastMessage.content}
                      </Typography>
                      {" — "}
                      <Typography component="span" variant="body2" color="textSecondary">
                        {moment(lastMessage.sentAt).fromNow()}
                      </Typography>
                    </>
                  ) : (
                    <Typography component="span" variant="body2" color="textPrimary">
                      Pas encore de message
                    </Typography>
                  )
                }
              />
            </ListItem>
          );
        })}
      </List>
    </Paper>
  );
};

export default MessageList;
