import axios from 'axios';
import authHeader from './auth-header';

const API_URL = 'http://localhost:8080/api/discussions/';
const MESSAGE_API_URL = 'http://localhost:8080/api/messages/';
const USER_API_URL = 'http://localhost:8080/api/user/';

export const messageService = {
    getMessages(userId) {
        return axios.get(API_URL, {
            params: { userId },
            headers: authHeader()
        })
            .then(response => {
                console.log('Messages data:', response.data);
                return response.data;
            })
            .catch(error => {
                console.error('Error fetching messages:', error);
                return [];
            });
    },

    getDiscussions(userId) {
        return axios.get(`${API_URL}discussions`, {
            params: { userId },
            headers: authHeader()
        })
            .then(response => {
                console.log('Discussions data:', response.data);
                return response.data;
            })
            .catch(error => {
                console.error('Error fetching discussions:', error);
                return [];
            });
    },

    getDiscussionMessages(discussionId) {
        return axios.get(`${MESSAGE_API_URL}discussion/${discussionId}`, { headers: authHeader() })
            .then(response => response.data)
            .catch(error => {
                console.error('Error fetching discussion messages:', error);
                throw error;
            });
    },

    sendMessage(discussionId, content) {
        return axios.post(`${MESSAGE_API_URL}discussion/${discussionId}/messages`, { content }, { headers: authHeader() })
            .then(response => response.data)
            .catch(error => {
                console.error('Error sending message:', error);
                throw error;
            });
    },
    searchUsers(query) {
        return axios.get(`${USER_API_URL}search`, { params: { query }, headers: authHeader() })
            .then(response => response.data)
            .catch(error => {
                console.error('Error searching users:', error);
                throw error;
            });
    },
    markAsRead(discussionId) {
        console.log("messageservice mark as read");
        return axios.patch(`${MESSAGE_API_URL}${discussionId}/markAsRead`, {}, { headers: authHeader() })
            .then(response => response.data)
            .catch(error => {
                console.error('Error marking messages as read:', error);
                throw error;
            });
    },
    createDiscussion(discussionData) {
        return axios.post(API_URL, discussionData, { headers: authHeader() })
            .then(response => response.data)
            .catch(error => {
                console.error('Error creating discussion:', error);
                throw error;
            });
    }
};
